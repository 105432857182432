import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AllSelectionsPage.css';

const AllSelectionsPage = () => {
    const [data, setData] = useState({});
    const [users, setUsers] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [revealedWeeks, setRevealedWeeks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const getEspnLink = (week) => {
        return `https://www.espn.com/nfl/scoreboard/_/week/${week}/year/2024/seasontype/2`;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [selectionsResponse, usersResponse, revealedWeeksResponse] = await Promise.all([
                    axios.get('/api/all-selections'),
                    axios.get('/api/users'),
                    axios.get('/api/revealed-weeks')
                ]);

                setData(selectionsResponse.data);
                setRevealedWeeks(revealedWeeksResponse.data);

                let allUsers = usersResponse.data;
                let loggedInUserData = null;

                // Only try to get the logged-in user if there's an auth token
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        const loggedInUserResponse = await axios.get('/api/user-id', {
                            headers: { Authorization: `Bearer ${token}` }
                        });
                        const loggedInUserId = loggedInUserResponse.data.userId;
                        loggedInUserData = allUsers.find(user => user.id === loggedInUserId);
                        
                        if (loggedInUserData) {
                            setLoggedInUser(loggedInUserData);
                            // Remove logged-in user from the array to sort
                            allUsers = allUsers.filter(user => user.id !== loggedInUserId);
                        }
                    } catch (error) {
                        console.log('Failed to fetch user data or user not logged in');
                    }
                }

                // Sort users based on their record
                allUsers.sort((a, b) => {
                    if (a.wins !== b.wins) return b.wins - a.wins;
                    if (a.losses !== b.losses) return a.losses - b.losses;
                    return b.ties - a.ties;
                });

                // Add logged-in user back to the beginning if it exists
                if (loggedInUserData) {
                    allUsers = [loggedInUserData, ...allUsers];
                }

                setUsers(allUsers);
                setError(null);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getResultIcon = (result) => {
        switch (result) {
            case 'W':
                return <span className="result-icon win">✓</span>;
            case 'L':
                return <span className="result-icon loss">✗</span>;
            case 'T':
                return <span className="result-icon tie">▢</span>;
            default:
                return null;
        }
    };

    const isWeekRevealed = (week) => revealedWeeks.includes(week);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="all-selections-container">
            <h2>All Selections</h2>
            <div className="all-selections-grid">
                <div className="all-selections-row header">
                    <div className="all-selections-cell user-cell">Players</div>
                    {Array.from({ length: 18 }, (_, i) => (
                        <div key={`week-${i + 1}`} className="all-selections-cell">
                            <a href={getEspnLink(i + 1)} target="_blank" rel="noopener noreferrer">
                                Week {i + 1}
                            </a>
                        </div>
                    ))}
                </div>
                {users.map((user, userIndex) => (
                    <div key={user.id} className={`all-selections-row ${user.id === loggedInUser?.id ? 'logged-in-user' : ''}`}>
                        <div className="all-selections-cell user-cell">
                            <div>{`${user.first_name || ''} ${user.last_name || ''} ${user.id === loggedInUser?.id ? '(You)' : ''}`}</div>
                            <div className="user-record">{`${user.wins}-${user.losses}-${user.ties}`}</div>
                        </div>
                        {Array.from({ length: 18 }, (_, weekIndex) => {
                            const week = weekIndex + 1;
                            const isRevealed = isWeekRevealed(week);
                            return (
                                <UserSelectionCell 
                                    key={`${user.id}-week-${week}`}
                                    week={week}
                                    isRevealed={isRevealed}
                                    selections={data[user.id]?.[week] || []}
                                    getResultIcon={getResultIcon}
                                />
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

const UserSelectionCell = ({ week, isRevealed, selections, getResultIcon }) => {
    if (!isRevealed) {
        return (
            <div className="all-selections-cell">
                <img 
                    src="/images/football.gif" 
                    alt="Not revealed" 
                    className="football-icon" 
                    title="Not revealed"
                />
            </div>
        );
    }

    return (
        <div className="all-selections-cell">
            {selections.map((selection, index) => (
                <div key={index} className="selection">
                    <img src={selection.logo_url} alt={selection.team_name} />
                    <div>{selection.team_name}</div>
                    {getResultIcon(selection.result)}
                </div>
            ))}
            {selections.length === 0 && <div className="no-selection">No selection</div>}
        </div>
    );
};

export default AllSelectionsPage;