import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPage.css';
import { useNavigate } from 'react-router-dom';

// Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

const AdminPage = () => {
    const [revealedWeeks, setRevealedWeeks] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkResult, setCheckResult] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [newPasswords, setNewPasswords] = useState({});

    useEffect(() => {
        checkAdminStatus();
        fetchData();
    }, []);

    const checkAdminStatus = async () => {
        try {
            const response = await axios.get('/api/check-admin', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            if (!response.data.isAdmin) {
                navigate('/'); // Redirect to home page if not admin
            }
        } catch (error) {
            console.error('Failed to check admin status:', error);
            navigate('/'); // Redirect to home page on error
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const [revealedWeeksResponse, usersResponse] = await Promise.all([
                axios.get('/api/revealed-weeks', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }),
                axios.get('/api/users', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                })
            ]);
            
            console.log('Revealed weeks response:', revealedWeeksResponse.data);
            
            // Create an array of all weeks, with revealed status
            const allWeeks = Array.from({ length: 18 }, (_, i) => ({
                week: i + 1,
                revealed: revealedWeeksResponse.data.includes(i + 1),
                reveal_time: null // You might want to fetch this separately if needed
            }));
            
            console.log('All weeks with revealed status:', allWeeks);
            
            setRevealedWeeks(allWeeks);
            setUsers(usersResponse.data);
            setError(null);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const toggleWeekReveal = async (week, revealed) => {
        try {
            console.log(`Toggling week ${week} to revealed=${revealed}`);
            const response = await axios.put(
                `/api/reveal-week/${week}`,
                { revealed },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            console.log('Server response:', response.data);
            fetchData(); // Refresh data after toggling
        } catch (error) {
            console.error('Failed to update week reveal status:', error.response?.data || error.message);
            setError(`Failed to update week status. ${error.response?.data?.message || error.message}`);
        }
    };

    const deleteUser = async (userId) => {
        if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
            try {
                await axios.delete(`/api/users/${userId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                fetchData();
            } catch (error) {
                console.error('Failed to delete user:', error.response?.data || error.message);
                setError(`Failed to delete user. ${error.response?.data?.message || error.message}`);
            }
        }
    };

    const resetUserPassword = async (userId) => {
        const newPassword = newPasswords[userId];
        if (!newPassword) {
            setError('Please enter a new password.');
            return;
        }
        try {
            await axios.post(`/api/reset-password/${userId}`, { newPassword }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setNewPasswords(prev => ({ ...prev, [userId]: '' }));
            setError('Password reset successfully.');
        } catch (error) {
            console.error('Failed to reset password:', error.response?.data || error.message);
            setError(`Failed to reset password. ${error.response?.data?.message || error.message}`);
        }
    };

    const resetRecords = async () => {
        if (window.confirm('Are you sure you want to reset all user records? This action cannot be undone.')) {
            try {
                await axios.post('/api/reset-records', {}, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                fetchData();
            } catch (error) {
                console.error('Failed to reset records:', error.response?.data || error.message);
                setError(`Failed to reset records. ${error.response?.data?.message || error.message}`);
            }
        }
    };

    const checkPicks = async (week) => {
        console.log(`Checking picks for week ${week}`);
        try {
            const response = await axios.get(`/api/check-picks/${week}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            console.log('Check picks response:', response.data);
            setCheckResult(response.data);
            setIsModalOpen(true);
            setError(null);
        } catch (error) {
            console.error('Failed to check picks:', error);
            console.error('Error response:', error.response);
            setError(`Failed to check picks. ${error.response?.data?.message || error.message}`);
            setCheckResult(null);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="admin-page">
            <h1>Admin Page</h1>
            
            {error && <div className="error">{error}</div>}
            
            <h2>Manage Revealed Weeks</h2>
            <table className="revealed-weeks-table">
                <thead>
                    <tr>
                        <th>Week</th>
                        <th>Revealed</th>
                        <th>Action</th>
                        <th>Check Picks</th>
                    </tr>
                </thead>
                <tbody>
                    {revealedWeeks.map((week) => (
                        <tr key={week.week}>
                            <td>{week.week}</td>
                            <td>{week.revealed ? 'Yes' : 'No'}</td>
                            <td>
                                <button 
                                    onClick={() => toggleWeekReveal(week.week, !week.revealed)}
                                    className={week.revealed ? 'hide-button' : 'reveal-button'}
                                >
                                    {week.revealed ? 'Hide' : 'Reveal'}
                                </button>
                            </td>
                            <td>
                                <button 
                                    onClick={() => checkPicks(week.week)}
                                    className="check-picks-button"
                                >
                                    Check Picks
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {checkResult && (
                    <div className="check-result">
                        <h3>Pick Check Results for Week {checkResult.week}</h3>
                        {checkResult.usersWithoutValidPicks.length === 0 ? (
                            <p>All users have valid picks for this week.</p>
                        ) : (
                            <>
                                <p>Users without valid picks:</p>
                                <ul>
                                    {checkResult.usersWithoutValidPicks.map((user, index) => (
                                        <li key={index}>{user.username} ({user.firstName} {user.lastName}) - {user.reason}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                )}
            </Modal>

            <h2>Manage Users</h2>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>({user.first_name}, {user.last_name})</td>
                            <td>
                                <button onClick={() => deleteUser(user.id)} className="delete-button">
                                    Delete
                                </button>
                                <input
                                    type="text"
                                    value={newPasswords[user.id] || ''}
                                    onChange={(e) => setNewPasswords(prev => ({ ...prev, [user.id]: e.target.value }))}
                                    placeholder="New password"
                                />
                                <button onClick={() => resetUserPassword(user.id)} className="reset-button">
                                    Reset User
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <h2>Reset Records</h2>
            <button onClick={resetRecords} className="reset-button">
                Reset All User Records
            </button>
        </div>
    );
};

export default AdminPage;