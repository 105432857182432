import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SelectionsPage.css';

const SelectionsPage = ({ loggedInUser }) => {
  const [teams, setTeams] = useState([]);
  const [selections, setSelections] = useState({});
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [revealedWeeks, setRevealedWeeks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const messageRef = useRef(null);
  const [selectionMethod, setSelectionMethod] = useState('dropdown');
  const [availableTeams, setAvailableTeams] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (showMessage && !errors.length) {
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [showMessage, errors]);

  const handleReset = async () => {
    setMessage('');
    setErrors([]);
    setShowMessage(true);
    try {
      const response = await axios.post(`/api/reset-selections/${loggedInUser.id}`);
      setSelections(response.data.updatedSelections || {});
      setMessage('Unrevealed selections have been reset');
    } catch (error) {
      console.error('Failed to reset selections:', error);
      setMessage('Failed to reset selections');
      if (error.response?.data?.error) {
        setErrors([error.response.data.error]);
      } else {
        setErrors([error.message]);
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    let timer;
    if (showSuccessMessage) {
      timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [showSuccessMessage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const [teamsResponse, selectionsResponse, revealedWeeksResponse] = await Promise.all([
          axios.get('/api/teams'),
          axios.get(`/api/user-record/${loggedInUser.id}`),
          axios.get('/api/revealed-weeks')
        ]);

        setTeams(teamsResponse.data);
        setSelections(selectionsResponse.data.selections || {});
        setRevealedWeeks(revealedWeeksResponse.data);

        // Debugging logs
        console.log('Teams:', teamsResponse.data);
        console.log('Selections:', selectionsResponse.data.selections);
        console.log('Revealed Weeks:', revealedWeeksResponse.data);
        
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loggedInUser]);

  useEffect(() => {
    updateAvailableTeams();
  }, [selections, teams, revealedWeeks]);

  const updateAvailableTeams = () => {
    const usedTeamIds = new Set(
      Object.entries(selections)
        .map(([key, value]) => {
          const week = parseInt(key.replace('second_', ''), 10);
          // Include the team in usedTeamIds if it's selected, regardless of whether the week is revealed
          return value;
        })
    );
    
    const available = teams.filter(team => !usedTeamIds.has(team.id));
    console.log('Available teams:', available); // Debug log
    setAvailableTeams(available);
  };

  const isWeekRevealed = (week) => {
    return revealedWeeks.includes(week);
  };

  const isTeamSelectable = (week, teamId) => {
    // If the week is revealed, the team is not selectable
    if (isWeekRevealed(week)) return false;
  
    const selectedTeams = Object.entries(selections).filter(([k, v]) => {
      return v === parseInt(teamId, 10);
    });

  // If the team is already selected twice, it's not selectable
  if (selectedTeams.length >= 2) return false;

  // If the team is already selected for this week, it's selectable
  if (selections[week] === parseInt(teamId, 10) || selections[`second_${week}`] === parseInt(teamId, 10)) {
    return true;
  }

  // If the team is selected in a revealed week, it's not selectable
  const isSelectedInRevealedWeek = selectedTeams.some(([k]) => {
    const w = parseInt(k.replace('second_', ''), 10);
    return isWeekRevealed(w);
  });
  return !isSelectedInRevealedWeek;
};

  const handleSelectionChange = (week, teamId, isSecondSelection = false) => {
    if (teamId === '' || isWeekRevealed(week)) return;

    setSelections(prevSelections => {
      const newSelections = { ...prevSelections };
      const key = isSecondSelection ? `second_${week}` : week;
      
      // Check if the team is already selected in a revealed week
      const isTeamRevealedElsewhere = Object.entries(prevSelections).some(([k, v]) => {
        const w = parseInt(k.replace('second_', ''), 10);
        return v === parseInt(teamId, 10) && revealedWeeks.includes(w) && k !== key;
      });

      if (isTeamRevealedElsewhere) {
        setErrors([`This team is already selected in a revealed week and cannot be chosen again.`]);
        return prevSelections;
      }

      newSelections[key] = parseInt(teamId, 10);
      return newSelections;
    });
  };

  const handleSubmit = async () => {
    setMessage('');
    setErrors([]);
    setShowMessage(false);
    try {
      const response = await axios.put(`/api/selections/${loggedInUser.id}`, {
        selections,
      });
      
      if (response.data.errors && response.data.errors.length > 0) {
        setErrors(response.data.errors);
        setMessage('Some selections could not be saved');
      } else {
        setMessage('All selections saved successfully');
      }
      
      setSelections(response.data.updatedSelections || {});
      setShowMessage(true);
    } catch (error) {
      console.error('Failed to save selections:', error);
      setMessage(`Failed to save selections: ${error.response?.data?.message || error.message}`);
      setShowMessage(true);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDragStart = (e, teamId) => {
    e.dataTransfer.setData('text/plain', teamId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, week, isSecondSelection = false) => {
    e.preventDefault();
    const teamId = e.dataTransfer.getData('text');
    handleSelectionChange(week, teamId, isSecondSelection);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="selections-page">
      <h1>Selections Page</h1>
      <h2>Welcome, {loggedInUser && loggedInUser.username}</h2>
      <div className="selection-controls">
        <div className="selection-method">
          <label>
            <input
              type="radio"
              value="dropdown"
              checked={selectionMethod === 'dropdown'}
              onChange={() => setSelectionMethod('dropdown')}
            />
            Dropdown Selection
          </label>
          <label>
            <input
              type="radio"
              value="dragdrop"
              checked={selectionMethod === 'dragdrop'}
              onChange={() => setSelectionMethod('dragdrop')}
            />
            Drag and Drop Selection
          </label>
        </div>
        <button onClick={handleReset} className="reset-button">Reset Unlocked Selections</button>
      </div>
      {showMessage && (
        <div 
          ref={messageRef}
          tabIndex="-1"
          className={`message-container ${errors.length ? 'error' : 'success'}`}
        >
          <p>{message}</p>
          {errors.length > 0 && (
            <div className="errors">
              <h3>The following selections could not be saved:</h3>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      
      {selectionMethod === 'dropdown' ? (
        <div className="selections-container">
          {Array.from({ length: 18 }, (_, i) => {
            const week = i + 1;
            const weekRevealed = isWeekRevealed(week);
            console.log(`Rendering Week ${week}, Revealed: ${weekRevealed}`); // Debugging log
            return (
              <div key={week} className={`week-selection ${weekRevealed ? 'revealed' : ''}`}>
                <h3>Week {week} {weekRevealed && <span className="revealed-indicator">REVEALED</span>}</h3>
                <select
                  value={selections[week] || ''}
                  onChange={(e) => handleSelectionChange(week, e.target.value)}
                  disabled={weekRevealed}
                >
                  <option value="">{weekRevealed ? `${teams.find(team => team.id === selections[week])?.name || 'No selection'}` : 'Select a team'}</option>
                  {!weekRevealed && teams.map((team) => (
                    <option key={team.id} value={team.id} disabled={!isTeamSelectable(week, team.id)}>
                      {team.city} {team.name}
                    </option>
                  ))}
                </select>
                {week >= 5 && (
                  <select
                    value={selections[`second_${week}`] || ''}
                    onChange={(e) => handleSelectionChange(week, e.target.value, true)}
                    disabled={weekRevealed}
                  >
                    <option value="">{weekRevealed ? `${teams.find(team => team.id === selections[`second_${week}`])?.name || 'No selection'}` : 'Select a second team'}</option>
                    {!weekRevealed && teams.map((team) => (
                      <option key={team.id} value={team.id} disabled={!isTeamSelectable(`second_${week}`, team.id)}>
                        {team.city} {team.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="drag-drop-selections">
          <div className="weeks-container">
            {Array.from({ length: 18 }, (_, i) => {
              const week = i + 1;
              const isRevealed = isWeekRevealed(week);
              return (
                <div key={week} className={`week-container ${isRevealed ? 'revealed' : ''}`}>
                  <h3>Week {week} {isRevealed && <span className="revealed-indicator">REVEALED</span>}</h3>
                  <div 
                    className="week-slot"
                    onDragOver={handleDragOver}
                    onDrop={(e) => !isRevealed && handleDrop(e, week)}
                  >
                    {selections[week] && (
                      <div className={`selected-team ${isRevealed ? 'revealed' : ''}`}>
                        {teams.find(team => team.id === selections[week])?.name}
                      </div>
                    )}
                  </div>
                  {week >= 5 && (
                    <div 
                      className="week-slot"
                      onDragOver={handleDragOver}
                      onDrop={(e) => !isRevealed && handleDrop(e, week, true)}
                    >
                      {selections[`second_${week}`] && (
                        <div className={`selected-team ${isRevealed ? 'revealed' : ''}`}>
                          {teams.find(team => team.id === selections[`second_${week}`])?.name}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="teams-container">
  {teams.map((team) => {
    const isSelectable = Array.from({ length: 18 }, (_, i) => i + 1).some(week => isTeamSelectable(week, team.id));
    return (
      <div
        key={team.id}
        className={`team-logo ${isSelectable ? '' : 'non-selectable'}`}
        draggable={isSelectable}
        onDragStart={(e) => isSelectable && handleDragStart(e, team.id)}
      >
        <img src={team.logo_url} alt={team.name} />
      </div>
    );
  })}
</div>
        </div>
      )}
      <button onClick={handleSubmit} className="save-button">Save Selections</button>
    </div>
  );
};

export default SelectionsPage;