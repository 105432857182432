import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import Register from './Register';
import Login from './Login';
import SelectionsPage from './SelectionsPage';
import AllSelectionsPage from './AllSelectionsPage';
import AdminPage from './AdminPage';
import './App.css';

const ADMIN_USERS = ['todd', 'darryl', 'nick', 'bestnick', 'djsensen', 'naguilar'];

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedUser = jwt.decode(token);
        setLoggedInUser(decodedUser);
        setIsAdmin(ADMIN_USERS.includes(decodedUser.username));
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    const decodedUser = jwt.decode(token);
    setLoggedInUser(decodedUser);
    setIsAdmin(ADMIN_USERS.includes(decodedUser.username));
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoggedInUser(null);
    setIsAdmin(false);
  };

  return (
    <Router>
      <div className="app-container">
        <header>
          <h1>ICD (TW) Pickem'</h1>
          <nav>
            {loggedInUser ? (
              <>
                <Link to="/selections">My Selections</Link>
                <Link to="/all-selections">PickEm Picks Grid</Link>
                {isAdmin && <Link to="/admin">Admin</Link>}
                <button onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <Link to="/login">Login</Link>
            )}
          </nav>
          {loggedInUser && (
            <div>
              <span>Welcome, {loggedInUser.username}</span>
            </div>
          )}
        </header>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route 
            path="/selections" 
            element={loggedInUser ? <SelectionsPage loggedInUser={loggedInUser} /> : <Navigate to="/login" />} 
          />
          <Route path="/all-selections" element={<AllSelectionsPage />} />
          <Route 
            path="/admin" 
            element={isAdmin ? <AdminPage /> : <Navigate to="/login" />} 
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;